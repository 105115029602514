import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import AboutImg from "../../../components/Image/AboutImg"

import {
  Title,
  SubTitle,
  Button,
  Textarea,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

import { FaRegPlayCircle, FaStopCircle, FaCopy } from "react-icons/fa"
const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  {
    name: "Morse Code",
    link: "/morse-code-conversion/",
  },
]
const seeAlsoArray = [
  "/ascii-and-binary-converter",
  "/ascii-code-converter",
  "/base32-decoder",
  "/base32-encoder",
  "/base64-decoder",
  "/base64-encoder",
  "/binary-decoder",
  "/binary-encoder",
  "/url-encoder-and-decoder",
]

function MorseCodeConversion(props) {
  let [strbox, setstrbox] = useState("")
  let [resultbox, setresultbox] = useState("")
  let [buttonTextCopy, setbuttonTextCopy] = useState("Copy")
  let resultTextarea = useRef("")
  let [errorMessage, seterrorMessage] = useState("")
  //   let [playing, setPlaying] = useState(false);
  function strboxC(e) {
    setstrbox(e.target.value)
  }
  const MORSE = {
    65: ".-",
    66: "-...",
    67: "-.-.",
    68: "-..",
    69: ".",
    70: "..-.",
    71: "--.",
    72: "....",
    73: "..",
    74: ".---",
    75: "-.-",
    76: ".-..",
    77: "--",
    78: "-.",
    79: "---",
    80: ".--.",
    81: "--.-",
    82: ".-.",
    83: "...",
    84: "-",
    85: "..-",
    86: "...-",
    87: ".--",
    88: "-..-",
    89: "-.--",
    90: "--..",
    48: "-----",
    49: ".----",
    50: "..---",
    51: "...--",
    52: "....-",
    53: ".....",
    54: "-....",
    55: "--...",
    56: "---..",
    57: "----.",
    32: "   ",
    46: ".-.-.-", //"."
    44: "--..--", //","
    63: "..--..", //"?"
    39: ".----.", //"'"
    33: "-.-.--", //"!"
    47: "-..-.", //"/"
    58: "---...", //":"
    59: "-.-.-.", //";"
    61: "-...-", //"="
    // 1: "-....-", //"-"
    95: "..--.-", //"_"
    34: ".-..-.", //'"'
    64: ".--.-.", //"@"
    40: "-.--.-", //"("
  }
  function stringButton() {
    const REGEX = /[^A-Za-z0-9.,?'!/:;=_"@(\s]/gm
    if (strbox === "") {
    } else if (REGEX.test(strbox)) {
      seterrorMessage(
        `-- Error: you can use Aa-Zz or/and 0-9 or .,?'!/:;=_"@( and space`,
        strbox.match(REGEX)
      )
      setTimeout(function () {
        seterrorMessage("")
      }, 2000)
    } else {
      toMorse(strbox)
    }
  }

  function morseCode() {
    const CHAR_REGEX = /[^-.\s]/gm
    const SPACE_REGEX = /(\s{3})/gm
    if (strbox === "") {
    } else if (CHAR_REGEX.test(strbox)) {
      seterrorMessage(
        '-- Error: Morse code should be "." dots or/and "-" dashes or/and  space',
        strbox.match(CHAR_REGEX)
      )
      setTimeout(function () {
        seterrorMessage("")
      }, 2000)
    } else {
      if (SPACE_REGEX.test(strbox)) {
        strbox = strbox.replace(SPACE_REGEX, " | ")
        toStr(strbox)
      } else {
        toStr(strbox)
      }
    }
  }
  const resetBtn = () => {
    setstrbox("")
    setresultbox("")
  }

  const toMorse = str => {
    str = str.toUpperCase()
    let arr = str.split("")
    let newResult = []
    for (let idx = 0; idx < arr.length; idx++) {
      let charVal = arr[idx].charCodeAt(0)
      if (MORSE.hasOwnProperty(charVal)) {
        newResult.push(MORSE[charVal])
      }
    }
    setresultbox(newResult.join(" "))
  }

  const toStr = mCode => {
    mCode = mCode.split(/\s/)
    let newStr = []
    for (let idx = 0; idx < mCode.length; idx++) {
      if (mCode[idx] === "|") {
        newStr.push(" ")
      } else {
        for (let obj in MORSE) {
          if (mCode[idx] === MORSE[obj]) {
            newStr.push(String.fromCharCode(Number(obj)))
          }
        }
      }
    }
    setresultbox(newStr.join(""))
  }
  const copyText = () => {
    if (resultbox !== "") {
      resultTextarea.current.select()
      let copied = document.execCommand("copy")
      if (copied) {
        setbuttonTextCopy("Copied")
        setTimeout(function () {
          setbuttonTextCopy("Copy")
        }, 1500)
      }
    }
  }

  const message = (a = "") => {
    seterrorMessage(a)
    setTimeout(function () {
      seterrorMessage("")
    }, 2000)
  }
  let oscillator = useRef(null)
  const play = () => {
    const cHAR_REGEX = /[^-.\s]/gm
    if (resultbox !== "") {
      if (cHAR_REGEX.test(resultbox)) {
        message("Only on morse code")
      } else if (window.AudioContext || window.webkitAudioContext) {
        let AudioContext = window.AudioContext || window.webkitAudioContext
        let ctx = new AudioContext()
        let dot = 1.2 / 20

        let t = ctx.currentTime
        oscillator.current = ctx.createOscillator()
        oscillator.current.type = "sine"
        oscillator.current.frequency.value = 600

        let gainNode = ctx.createGain()
        gainNode.gain.setValueAtTime(0, t)

        resultbox.split("").forEach(function (letter) {
          switch (letter) {
            case ".":
              gainNode.gain.setValueAtTime(1, t)
              t += dot
              gainNode.gain.setValueAtTime(0, t)
              t += dot
              break
            case "-":
              gainNode.gain.setValueAtTime(1, t)
              t += 3 * dot
              gainNode.gain.setValueAtTime(0, t)
              t += dot
              break
            case " ":
              t += 3 * dot
              break
            default:
              console.log("1")
          }
        })
        oscillator.current.connect(gainNode)
        gainNode.connect(ctx.destination)
        // let time = ctx.baseLatency * 1000 * resultbox.length
        // setPlaying(true)
        // setTimeout(function () {
        //   setPlaying(false)
        // }, time)

        oscillator.current.start()
      } else {
        message("Your browser doesn't support AudioContext")
      }
    } else {
      message("Please make the conversion to morse code before play the sound")
    }
  }

  const stopSound = () => {
    if (oscillator.current !== null) {
      oscillator.current.stop()
    }
  }

  // const downloadSound = () => {
  //   if (oscillator.current !== null) {
  //     console.log(oscillator.current.createMediaStreamDestination())
  //   }
  // }

  return (
    <Layout location={props.location}>
      <SEO
        title="Morse Code to Text or Text to Morse Code Conversion - Morse Code
      translator"
        description="Morse Code, Morse Code to Text or Text to Morse Code Conversion, Simple Morse Code translator step by step, Morse Code translator online."
        keywords={[
          "Text To Morse Code,Morse encoder decoder,Morse encoder,Morse decoder,Morse converter, decoder encoder Morse, code translator, Morse Code, Morse Code translator,Morse Code to text, what Morse Code, convert Morse Code, Morse Code convertion, Morse Code translator,translate Morse Code.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Morse Code Translator</Title>
        <SubTitle>Morse Code To Text Or Text To Morse code</SubTitle>

        <Textarea
          value={strbox}
          onChange={strboxC}
          maxLength="1000"
          placeholder="String to Morse code: use Aa-Zz 0-9. Morse code to String: use dots'.' or/and dashes '-', separate between letters with 1 space and between words 3 spaces'   '."
        ></Textarea>

        <p>{errorMessage}</p>

        <Textarea
          ref={resultTextarea}
          value={resultbox}
          placeholder="Output"
          readOnly
        ></Textarea>
        <div>
          <Button
            type="button"
            className="mb-2"
            borderColor="#1da1f2"
            color="#1da1f2"
            hoverColor="white"
            hoverBorderColor="#1da1f2"
            hoverBackgroundColor="#1da1f2"
            onClick={stringButton}
          >
            TO MORSE
          </Button>

          <Button
            className="mb-2"
            type="button"
            borderColor="#1da1f2"
            color="white"
            backgroundColor="#1da1f2"
            hoverColor="#1da1f2"
            hoverBorderColor="#1da1f2"
            onClick={morseCode}
          >
            TO STRING
          </Button>

          <Button
            type="button"
            className="mb-2"
            borderColor="#ea4336"
            color="white"
            backgroundColor="#ea4336"
            hoverBorderColor="#ea4336"
            hoverBackgroundColor="white"
            hoverColor="#ea4336"
            onClick={resetBtn}
          >
            RESET
          </Button>
        </div>
        <br />
        <div>
          <Button
            type="button"
            borderColor="#FF8C00"
            color="#FF8C00"
            hoverColor="white"
            hoverBorderColor="#FF8C00"
            hoverBackgroundColor="#FF8C00"
            onClick={copyText}
          >
            <FaCopy /> {` ${buttonTextCopy}`}
          </Button>

          <Button
            type="button"
            borderColor="#F55A34"
            color="#F55A34"
            hoverColor="white"
            hoverBorderColor="#F55A34"
            hoverBackgroundColor="#F55A34"
            onClick={play}
          >
            <FaRegPlayCircle /> Play
          </Button>

          <Button
            type="button"
            borderColor="#F55A34"
            color="#F55A34"
            hoverColor="white"
            hoverBorderColor="#F55A34"
            hoverBackgroundColor="#F55A34"
            onClick={stopSound}
          >
            <FaStopCircle /> Stop
          </Button>
          {/* <div style={{ padding: "5px" }}>
                <button
                  type="button"
                  className="button"
                  onClick={downloadSound}
                >
                  <FaDownload />
                </button>
              </div> */}
        </div>

        <br />
        <h3>Morse Code!</h3>
        <p>
          Morse code is a method utilized in telecommunication to encode text as
          standardized sequences of two different signal durations, named dots,
          and dashes or dits and dahs.
          <br />
          Morse code characters are dots'.' or/and dashes '-', separate between
          letters with 1 space and between words 3 spaces' '.
        </p>
        <br />
        <FlexDiv maxWidth="500px">
          <AboutImg filename="morse.png" alt="morse code translator" />
        </FlexDiv>
        <br />

        <h3>Morse Code Translator</h3>
        <p>
          Convert text to Morse Code by adding text in the input above and click
          on the button "To Morse", and to convert morse code to text add morse
          code in the input above and click on the button "To String".
        </p>

        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
      </div>
    </Layout>
  )
}

export default MorseCodeConversion
